import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import profile from '../assets/images/mum-profile-crop.jpg'

class AboutMe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      window: {
        width: 0,
        height: 0
      }
    }
  }

  componentDidMount() {
    this.getWindowState();
    window.addEventListener('resize', this.getWindowState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getWindowState);
  }

  getWindowState = () => {
    this.setState({
      window: {
        width: window.innerWidth,
        height: window.innerHeight
      }
    })
  }

  render() {
    const {window} = this.state;

    return(
      <Layout metadata={this.props.data.site.siteMetadata} currentPage="About Me">
      <div>
        <h1>About Me</h1>
        <p>Hello, I’m Máire and I’m a counsellor and psychotherapist. Before I started practicing I had over 
          14 years experience working in various educational settings. I am working in the caring profession 
          for over 25 years. I trained as a psychotherapist with the Tivoli Institute in Dublin.</p>
        <img src={profile} alt="profile" style={{float: 'right', width: window.width > 600 ? 250 : 180, marginLeft: 20}} />
        <p>I have my own private practice in a discreet, calm and quiet setting. I am an accredited member of 
          the Irish Association of Humanistic and Integrative Psychotherapy
          {' '}<a href="https://iahip.org/Sys/PublicProfile/53252154/5581868">(IAHIP)</a>{' '}
          and the Irish Association for Counselling and Psychotherapy
          {' '}<a href="https://www.iacp.ie/page/therapists?x=0&y=0&byname=maire+mcandrew">(IACP)</a>.{' '}
          I am a registrant of the Irish Council for Psychotherapy
          {' '}<a href="http://www.psychotherapycouncil.ie/find/?searchpage=1&county=&surname=mcandrew&address=&gender=&organisation=&referrals=&wheelchair=&workswith=&language=&si=&option=view&id=1819">
            (ICP)
          </a>{' '}
          and I am a holder of the European Certificate of Psychotherapy (ECP) (European Association for Psychotherapy
          {' '}<a href="https://ecp.europsyche.org/wstore/memberServlet?Member=409441_McAndrew">(EAP)</a>).</p>
          <p>
            In counselling and psychotherapy clients use different resources such as mindfulness, goal setting, 
            positive psychology, keeping a mood diary, self- talking and self-care which could help them 
            manage their emotions and live their lives in a more satisfying way. This could help them take 
            control of their lives, develop as individuals and help their relationships with others.
          </p>
          <p>
            As a counsellor and psychotherapist, I use a humanistic and integrative approach, embracing person-centred, 
            gestalt, and psychodynamic styles as well as Cognitive Behavioural Therapy (CBT) and other techniques and 
            methodologies that are designed to facilitate the exploration of problems. I place particular emphasis on 
            skills and theoretical models concerned with the development of a therapeutic relationship. But, no form 
            of therapy is completely adequate in all situations and so I use my experience and know how to choose 
            techniques and strategies that are most pertinent to the situation and for that moment in time.
          </p>
          <p>
            My counselling and psychotherapy practice is situated in Ross House, Victoria Place, Eyre Square in Galway City. 
            I offer counselling & psychotherapy in a warm, safe and non-judgemental atmosphere to explore and reflect 
            upon difficulties using the latest know how, techniques and research applied in a professional and confidential way.
          </p>
          <p>
            Thank you for taking the time to visit my website, which I hope has helped to give you insight into the work that I do.
          </p>
      </div>
      </Layout>
    )
  }
}

export default AboutMe

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`